import { Layout } from '../components/layout'
import { PageHeader } from '../components/pageHeader'
import * as React from 'react'
import { ServiceLink } from '../components/serviceLink'
import { FaWind } from 'react-icons/all'

function ArchiveServices () {
  return (
    <>
      <div className="flex flex-col items-center w-full">
        <div className="flex flex-col container max-w-md mt-10 mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
          <ul className="flex flex-col divide-y dark:divide-gray-700 w-full">
            <ServiceLink
              title={'Confluence'}
              description={'Our old wiki software.'}
              href={'https://wiki.lasnq.physnet.uni-hamburg.de/'}
              icon={<FaWind />}
            />
          </ul>
        </div>
      </div>
    </>
  )
}

function Content () {
  return (
    <Layout>
      <PageHeader>Archive</PageHeader>
      <div className="container flex flex-col px-6 py-4 mx-auto space-y-6 lg:h-[32rem] lg:py-16 lg:flex-row items-baseline">
        <ArchiveServices />
      </div>
    </Layout>
  )
}

export default function Lab27Page () {
  return <Content />
}
